<template>
     <div class="middle">
        <div class="container container_tertiary">
            <div class="row">
                <div class="col col_12">
                    <div class="main">
                        <div class="main__header header content-center">
                            <logo></logo>
                        </div>
                        <div class="main__content">
                            <slot></slot>
                            <main-footer></main-footer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Logo from "../components/Logo0.vue"
    import MainFooter from "../components/MainFooter.vue"

    export default {
        name: "StartLayout",
        components: {
            "logo": Logo,
            "main-footer": MainFooter,
        },
    }
</script>
